import React from "react";
import im1 from "../Imge/blog-s-1-1-1-1-395x274.jpg";
import im2 from "../Imge/blog-s-1-2-1-1-395x274.jpg";
import im3 from "../Imge/img-3-2-1-395x274.jpg";
// -------------------
import brand1 from '../Imge/brand_1_1.png'
import brand2 from '../Imge/brand_1_3.png'
const Blog = () => {
  return (
    <>
      <div>
        {/* Heders start  */}
        <div className="text-center md:my-10 my-5">
          <div className="text-Red font-bold text-xl">NEWS & UPDATES</div>
          <div className="md:text-4xl text-2xl font-bold">
            Latest Blog Posts
          </div>
        </div>
      </div>

      <div className=" flex justify-center items-center md:my-20 my-10">
        <div className="flex md:flex-row flex-col gap-20">
          <div className="flex flex-col gap-5">
            <div className="flex md:flex-row flex-col gap-10 bg-slate-50">
              <div>
                <img
                  class="object-cover w-full rounded-t-lg h-96 md:h-auto
     md:rounded-lg md:w-[300px] "
                  src={im1}
                  alt=""
                />{" "}
              </div>
              <div class="flex flex-col gap-5 justify-between p-4 leading-normal md:w-[300px] hover:text-red-500 ">
                <div className="flex gap-5">
                    <div>
                    16 JUN, 2022
                    </div>
                    <div>
                    BATTERY CHARGE
                    </div>
                </div>
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Give Your Small Car the Horn It Deserves
                </h5>
                <p class="mb-3  font-extrabold text-red-600">
                  Read More 
                </p>
              </div>
            </div>
            <div className="flex md:flex-row flex-col-reverse  gap-10 bg-slate-50">
            <div class="flex flex-col gap-5 justify-between p-4 leading-normal md:w-[300px] hover:text-red-500 ">
                <div className="flex gap-5">
                    <div>
                    16 JUN, 2022
                    </div>
                    <div>
                    BATTERY CHARGE
                    </div>
                </div>
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Sustainable strategic theme areas
                </h5>
                <p class="mb-3  font-extrabold text-red-600">
                  Read More 
                </p>
              </div>
              <div>
                <img
                  class="object-cover w-full rounded-lg h-96 md:h-auto
    md:rounded-none md:rounded-l-lg md:w-[300px] "
                  src={im2}
                  alt=""
                />{" "}
              </div>
            </div>
          </div>

          <div class="max-w-sm  rounded-lg shadow bg-slate-50">
            <a href="">
              <img class="rounded-t-lg" src={im3} alt="" />
            </a>
            <div class="p-5 ">
              <a href="">
              <div className="flex flex-col gap-5 gap-5 hover:text-red-500">
                    <div>
                    16 JUN, 2022
                    </div>
                    <div>
                    BATTERY CHARGE
                    </div>
                </div>
                <h5 class="mb-2 text-2xl font-bold tracking-tight hover:text-red-500
                 text-gray-900 dark:text-white">
                communicate timely meta services for
                </h5>
              </a>
              <p class="mb-3  font-extrabold text-red-600">
                  Read More 
                </p>
           
            </div>
          </div>
        </div>
      </div>


{/* reddd  */}
<div className=" bg-red-500">


    <div className="flex md:flex-row flex-col gap-20 justify-center items-center md:py-20 py-10">
        <div>
            <img src={brand1} alt="" />
        </div>
        <div>
            <img src={brand1} alt="" />
        </div>
        <div>
            <img src={brand2} alt="" />
        </div>
        <div>
            <img src={brand2} alt="" />
        </div>
    </div>

</div>

    </>
  );
};

export default Blog;
