import React from "react";
import truc4 from '../images/truc4.png' 
const Contect = () => {
  return (
    <div className="md:my-20 my-10">
      <div className="md:my-10 my-5">
        <div className="md:py-5 py-2">
          <div className="flex flex-col items-center md:mx-2 mx-5">
            <h1 className="font-bold text-4xl  mx-2 text-blue-500">
              Requst A call for You
            </h1>

            <div></div>
          </div>
        </div>
      </div>

      {/* content start  */}

      <div className="flex md:flex-row flex-col justify-center items-center gap-10">
        <div className="flex flex-col gap-10">
          <div>
            <input type="text" placeholder="Name" className="bg-white shadow-2xl rounded-lg px-2 py-3 w-80 border-blue-400" />
          </div>
          <div>
            <input type="text" placeholder="Email" className="bg-white shadow-2xl rounded-lg px-2 py-3 w-80 border-blue-400"  />
          </div>
          <div>
            <input type="text" placeholder="Phone Number"  className="bg-white shadow-2xl rounded-lg px-2 py-3 w-80 border-blue-400" />
          </div>
          <div>
            <input type="text" placeholder="Message" className="bg-white shadow-2xl rounded-lg px-2 py-3 w-80 h-60 border-blue-400"  />
          </div>
          <div className="flex justify-center items-center">
            <button className="bg-blue-500 text-white uppercase md:px-10 px-5 md:py-3 py-2 rounded-2xl">Send </button>
          </div>
        </div>
        <div className="px-2">
            <img src={truc4} alt="" />
        </div>
      </div>

      {/* content End  */}
    </div>
  );
};

export default Contect;
