import React from 'react'
import Navbar from './Componets/Navbar'
import { Helmet } from 'react-helmet'
import About from './Componets/About'
import Services from './Componets/Services'
import Vehicles from './Componets/Vehicles'
import Testimonials from './Componets/Testimonials'
import Choose from './Componets/Choose'
import Contect from './Componets/Contect'
import Footer from './Componets/Footer'


const Logisolution = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Transport || Logisolution </title>
      </Helmet>
 
    <div>
        
        <Navbar/>
        <About/>
        <Services/>
        <Vehicles/>
        <Testimonials/>
        <Choose/>
        <Contect/>
        <Footer/>
       
    </div>
    </>
  )
}

export default Logisolution