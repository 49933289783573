import React from "react";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { HiOutlineMailOpen } from "react-icons/hi";
import { ImHeadphones } from "react-icons/im";
import logo from "../Imge/Group 12443.png";
const Topbar = () => {
  return (
    <div className="bg-gray-100 md:py-5 py-2 ">
      <div className="flex md:flex-row flex-col md:gap-36 gap-5  items-center justify-center  text ">
        <div className="md:block hidden">Welcome To Mechon Car Repair Company</div>
        <div> Office Hours: Mon-Fri: 09:00AM-6:00PM</div>

        <div className=" flex  gap-5">
          <div>Follow Us:</div>
          <div>
            <FaFacebookF />
          </div>
          <div>
            <FaTwitter />
          </div>
          <div>
            <FaLinkedinIn />
          </div>
        </div>
      </div>

      {/* contet Start  */}

      <div className="flex md:flex-row flex-col items-center justify-center md:gap-20 gap-5">
        <div>
          <img src={logo} alt="" />
        </div>
        <div className="bg-white flex md:flex-row flex-col w-full items-center
         md:gap-40 gap-5 md:py-5 py-3 md:px-20 md:mt-5 md:-mb-5 ">
          <div>
            <input
              type="text"
              className="md:px-16 bg-gray-100 py-3 border-red-400 "
              placeholder="Shearch KeyWord ...."
            />
          </div>
          <div className="flex gap-4 items-center ">
           
            <div className="md:block hidden">
              <HiOutlineMailOpen className="text-4xl"/>
            </div>
            <div className="md:block hidden">
              MAKE AN EMAIL <br />
              info@cery.com
            </div>
          </div>
          <div className="flex gap-4 items-center ">
            <div className="md:block hidden">
              <ImHeadphones className="text-4xl"/>
            </div>
            <div className="md:block hidden">
              CALL US 24/7 <br />
              8888888888
            </div>
          </div>{" "}
        </div>
      </div>
      {/* contet End */}
    </div>
  );
};

export default Topbar;
