import React from "react";
import { IoIosAirplane } from "react-icons/io";
import { GiAirtightHatch, GiCargoShip } from "react-icons/gi";
const SERVICES = () => {
  return (
    <div>
      <div className="text-center md:my-10 my-5">
        <div className="text-Red font-bold text-xl">OUR SERVICES</div>
        <div className="md:text-4xl text-2xl font-bold">
          Best Logistic Services
        </div>
      </div>
      <div className="flex  justify-center items-center">
        <div className="max-w-[1280px] flex gap-10 md:flex-row flex-col md:mx-5 mx-10 md:my-10 my-5">
          <div className="flex md:flex-row flex-col  ">
            <div className="md:w-[300px] w-96">
              <div className="bg-Red flex items-center gap-5 md:px-5 px-2 md:py-5 py-3 justify-center  ">
                <div>
                  <IoIosAirplane className="font-bold text-4xl" />
                </div>
                <div className=" font-bold text-2xl">Air Freight</div>
              </div>
              <div className="md:mx-10 mx-5 text-center">
                <p className="">
                  Diam amet eos at no eos sit lorem, amet rebum ipsum clita stet
                  diam sea est diam
                </p>
                <p className="text-Red underline">Read More</p>
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col ">
            <div className="md:w-[300px] w-96">
              <div className="bg-Red flex items-center gap-5 md:px-5 px-2 md:py-5 py-3 justify-center  ">
                <div>
                  <GiCargoShip className="font-bold text-4xl" />
                </div>
                <div className=" font-bold text-2xl">Ocean Freight</div>
              </div>
              <div className="md:mx-10 mx-5 text-center">
                <p className="">
                  Diam amet eos at no eos sit lorem, amet rebum ipsum clita stet
                  diam sea est diam
                </p>
                <p className="text-Red underline">Read More</p>
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col ">
            <div className="md:w-[300px] w-96">
              <div className="bg-Red flex items-center gap-5  md:px-5 px-2 md:py-5 py-3 justify-center ">
                <div>
                  <GiAirtightHatch className="font-bold text-4xl" />
                </div>
                <div className=" font-bold text-2xl">Land Transport</div>
              </div>
              <div className="md:mx-10 mx-5 text-center">
                <p className="">
                  Diam amet eos at no eos sit lorem, amet rebum ipsum clita stet
                  diam sea est diam
                </p>
                <p className="text-Red underline">Read More</p>
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col ">
            <div className="md:w-[300px] w-96">
              <div className="bg-Red flex items-center gap-5  md:px-5 px-2 md:py-5 py-3 justify-center ">
                <div>
                  <GiCargoShip className="font-bold text-4xl" />
                </div>
                <div className=" font-bold text-2xl">Cargo Storage</div>
              </div>
              <div className="md:mx-10 mx-5 text-center">
                <p className="">
                  Diam amet eos at no eos sit lorem, amet rebum ipsum clita stet
                  diam sea est diam
                </p>
                <p className="text-Red underline">Read More</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SERVICES;
