import React from "react";
import Truc1 from "../images/truc1.png";
import Truc2 from "../images/truc2.png";
import Truc3 from "../images/truc3.png";
const Vehicles = () => {
  return (
    <div>
      <div>
        <div>
          <div className="md:py-5 py-2">
            <div className="flex flex-col items-center md:mx-2 mx-5">
              <h1 className="font-bold text-4xl  mx-2 text-blue-500">
                Our Vehicles
              </h1>

              <div></div>
              <div>
                nternet. It uses a dictionary of over 200 Latin words, combined
                with .
              </div>
            </div>
          </div>
        </div>

        {/* contet  */}
        <div
          
          className="flex justify-center md:flex-row flex-col gap-10"
        >
          <div data-aos="fade-down" className="bg-gray-100 rounded-xl hover:bg-white shadow-2xl">
            <img src={Truc1} alt="" className="md:w-[400px] w-96" />
          </div>
          <div data-aos="fade-up" className="bg-gray-100 rounded-xl hover:bg-white shadow-2xl">
            <img src={Truc2} alt="" className="md:w-[400px] w-96" />
          </div>
          <div  data-aos="fade-down" className="bg-gray-100 rounded-xl hover:bg-white shadow-2xl">
            <img src={Truc3} alt="" className="md:w-[400px] w-96" />
          </div>
        </div>

        <div className="flex justify-center items-center md:my-10 my-5 text-bold text-2xl">
          TRUCK
        </div>
        <div className="flex justify-center items-center md:my-20 my-5 text-bold text-2xl">
          Read More --------------
        </div>
      </div>
    </div>
  );
};

export default Vehicles;
