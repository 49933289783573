import React from "react";
import About1 from "../img/about.jpg";
const About = () => {
  return (
    <div>
      <div className="flex justify-center ">
        <div className="flex justify-between md:flex-row flex-col md:my-10 my-5 gap-20 max-w-[1280px]">
          <div>
            <img
              src={About1}
              alt=""
              className="md:w-[500px] md:h-[400px] w-96 h-80 md:px-2 px-5 "
            />
            <div className="flex justify-center items-center">
                
         
            <div className="md:w-[486px] md:h-[100px] w-[350px] h-[50px] md:px-2 px-5 items-center flex justify-center font-bold    bg-Red text-center text-2xl md:text-4xl">
            25+ Years Experience
            </div>   </div>
          </div>
          <div className="flex flex-col md:px-2 px-5  gap-10 md:w-[450px] md:h-[400px] w-96 h-96 justify-center">
            <p className="text-Red font-bold text-xl">About Us</p>

            <h1 className="font-bold text-4xl  mx-2">
              Trusted & Faster Logistic Service Provider{" "}
            </h1>

            <div>
              Dolores lorem lorem ipsum sit et ipsum. Sadip sea amet diam dolore
              sed et. Sit rebum labore sit sit ut vero no sit. Et elitr stet
              dolor sed sit et sed ipsum et kasd ut. Erat duo eos et erat sed
              diam duo
            </div>
            <div>
              <button className=" rounded-xl md:px-5 px-2 py-2 font-bold md:text-2xl text-xl">
              Play Video
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
