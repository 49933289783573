import React from "react";
import image1 from '../img/blog-1.jpg'
import image2 from '../img/blog-2.jpg'

import user1 from '../img/user.jpg'
const Blogs = () => {
  return (
    <div>
      <div>
        {/* Heders start  */}
        <div className="text-center md:my-10 my-5">
          <div className="text-Red font-bold text-xl">OUR BLOG</div>
          <div className="md:text-4xl text-2xl font-bold">Latest From Blog</div>
        </div>

        {/* Heders END  */}

        <div className="flex justify-center ">
        <div className="max-w-[1280px] flex justify-center md:flex-row flex-col flex-wrap md:my-5 my-5 gap-10">
          <div className=" md:mx-0 mx-5">
            <div className="  ">
              <div className="items-center justify-center flex flex-col gap-3 
              font-bold text-lg ">
                <img src={image1} alt="" className="" />
              </div>
              <div className="bg-gray-100 ">

          
              <div
                className=" flex  gap-5 
                 font-bold text-lg  md:py-5 py-3 text-center items-center "
              >
                <div><img src={user1} alt="" className="rounded-full w-20 h-20"/></div>
                <div>John Doe</div>
                <div>Web Design</div>
              </div>

              <div className="text-2xl font-bold text-justify">
              Kasd tempor diam sea justo dolor
              </div>
              <div className="md:w-[500px] w-96 text-justify" >
              Dolor sea ipsum ipsum et. Erat duo lorem magna vero dolor dolores. Rebum eirmod no dolor
               diam dolor amet ipsum. Lorem lorem sea sed diam est lorem magna
              </div>
              <div className="uppercase text-Red underline">
                read More
              </div>
              </div>
            </div>
          </div>
          <div className=" md:mx-0 mx-5">
            <div className="  ">
              <div className="items-center justify-center flex flex-col gap-3 
              font-bold text-lg ">
                <img src={image2} alt="" className="" />
              </div>
              <div className="bg-gray-100 ">

          
              <div
                className=" flex  gap-5 
                 font-bold text-lg  md:py-5 py-3 text-center items-center "
              >
                <div><img src={user1} alt="" className="rounded-full w-20 h-20"/></div>
                <div>John Doe</div>
                <div>Web Design</div>
              </div>

              <div className="text-2xl font-bold text-justify">
              Kasd tempor diam sea justo dolor
              </div>
              <div className="md:w-[500px] w-96 text-justify" >
              Dolor sea ipsum ipsum et. Erat duo lorem magna vero dolor dolores. Rebum eirmod no dolor
               diam dolor amet ipsum. Lorem lorem sea sed diam est lorem magna
              </div>
              <div className="uppercase text-Red underline">
                read More
              </div>
              </div>
            </div>
          </div>
     
          
         
        </div>
      </div>


      </div>
    </div>
  );
};

export default Blogs;
