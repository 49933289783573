import React from 'react'
import Testimonial1 from "../img/testimonial-1.jpg"
import Testimonial2 from "../img/testimonial-2.jpg"
import Testimonial3 from "../img/testimonial-3.jpg"
import Testimonial4 from "../img/testimonial-4.jpg"
const TESTIMONIAL = () => {
  return (
    <div>

      {/* Heders start  */}
      <div className="text-center md:my-10 my-5">
        <div className="text-Red font-bold text-xl">TESTIMONIAL</div>
        <div className="md:text-4xl text-2xl font-bold">
        Our Clients Say
        </div>
      </div>

      {/* Heders END  */}  


      <div className="flex justify-center ">
          <div className="max-w-[1280px] flex justify-center md:flex-row flex-col flex-wrap md:my-5 my-5 gap-10">
            <div className=" md:mx-0 mx-5">
              <div className="bg-gray-100 text-black md:w-[280px] w-96">
                <div className="bg-gray-100  flex  gap-2 items-center justify-center font-bold text-lg  md:py-5 py-3 ">
                  <div><img src={Testimonial1} alt=""  className='rounded-full'/></div>
                  <div>Client Name <br /> -Profession

                  </div>
                </div>
                <div className="items-center justify-center flex flex-col gap-3
                 md:py-5 py-3  md:px-5 px-3 
                 text-lg ">
                 
                  <div>Sed ea amet kasd elitr stet, stet rebum et
                     ipsum est duo elitr clita lorem. Dolor ipsum sanct clita
                     </div>
                </div>
              </div>
          
            </div>
            <div className=" md:mx-0 mx-5">
              <div className="bg-gray-100 text-black md:w-[280px] w-96">
                <div className="bg-gray-100  flex  gap-2 items-center justify-center font-bold text-lg  md:py-5 py-3 ">
                  <div><img src={Testimonial2} alt=""  className='rounded-full'/></div>
                  <div>Client Name <br /> -Profession

                  </div>
                </div>
                <div className="items-center justify-center flex flex-col gap-3
                 md:py-5 py-3  md:px-5 px-3 
                 text-lg ">
                 
                  <div>Sed ea amet kasd elitr stet, stet rebum et
                     ipsum est duo elitr clita lorem. Dolor ipsum sanct clita
                     </div>
                </div>
              </div>
          
            </div>
            <div className=" md:mx-0 mx-5">
              <div className="bg-gray-100 text-black md:w-[280px] w-96">
                <div className="bg-gray-100  flex  gap-2 items-center justify-center font-bold text-lg  md:py-5 py-3 ">
                  <div><img src={Testimonial3} alt=""  className='rounded-full'/></div>
                  <div>Client Name <br /> -Profession

                  </div>
                </div>
                <div className="items-center justify-center flex flex-col gap-3
                 md:py-5 py-3  md:px-5 px-3 
                 text-lg ">
                 
                  <div>Sed ea amet kasd elitr stet, stet rebum et
                     ipsum est duo elitr clita lorem. Dolor ipsum sanct clita
                     </div>
                </div>
              </div>
          
            </div>
            <div className=" md:mx-0 mx-5">
              <div className="bg-gray-100 text-black md:w-[280px] w-96">
                <div className="bg-gray-100  flex  gap-2 items-center justify-center font-bold text-lg  md:py-5 py-3 ">
                  <div><img src={Testimonial4} alt=""  className='rounded-full'/></div>
                  <div>Client Name <br /> -Profession

                  </div>
                </div>
                <div className="items-center justify-center flex flex-col gap-3
                 md:py-5 py-3  md:px-5 px-3 
                 text-lg ">
                 
                  <div>Sed ea amet kasd elitr stet, stet rebum et
                     ipsum est duo elitr clita lorem. Dolor ipsum sanct clita
                     </div>
                </div>
              </div>
          
            </div>
           
         
          </div>
        </div>



    </div>
  )
}

export default TESTIMONIAL