import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
import img1 from "../Imge/service_1_1.jpg";
import img2 from "../Imge/service_1_2.jpg";
import img3 from "../Imge/service_1_3.jpg";
import img4 from "../Imge/service_1_4.jpg";
import feature1 from "../Imge/feature_1_1.svg";
import feature2 from "../Imge/feature_1_2.svg";
const SERVICESWEPROVIDE = () => {
  return (
    <div>
      <div>
        {/* Heders start  */}
        <div className="text-center md:my-10 my-5">
          <div className="text-Red font-bold text-xl">SERVICES WE PROVIDE</div>
          <div className="md:text-4xl text-2xl font-bold">Our Service Area</div>
        </div>
      </div>

      {/* content start  */}

      <div
        className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center
     md:flex md:px-8"
      >
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          <SwiperSlide className="relative">
            <div className="flex  md:flex-row flex-col gap-5">
              <div class="block rounded-lg hover:cursor-zoom-in hover:text-white border-red-500 border-2 border-double hover:bg-red-500 duration-700  bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img class="rounded-t-lg" src={img1} alt="" />
                  <a href="#!">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
                <div class="p-6  ">
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                    Service 01
                  </h5>
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                    Electrical System
                  </h5>
                  <p class="mb-4 text-base  dark:text-neutral-200">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <button
                    type="button"
                    class="inline-block rounded bg-primary
                     px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal
                      hover:text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    View Details
                  </button>
                </div>
              </div>
              <div class="block rounded-lg hover:cursor-zoom-in hover:text-white border-red-500 border-2 border-double hover:bg-red-500 duration-700  bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img class="rounded-t-lg" src={img2} alt="" />
                  <a href="#!">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
                <div class="p-6  ">
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                    Service 02
                  </h5>
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                    Auto Car Repair
                  </h5>
                  <p class="mb-4 text-base  dark:text-neutral-200">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <button
                    type="button"
                    class="inline-block rounded bg-primary
                     px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal
                      hover:text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    View Details
                  </button>
                </div>
              </div>
              <div class="block rounded-lg hover:cursor-zoom-in hover:text-white border-red-500 border-2 border-double hover:bg-red-500 duration-700  bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img class="rounded-t-lg" src={img3} alt="" />
                  <a href="#!">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
                <div class="p-6  ">
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                    Service 03
                  </h5>
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                    Engine Diagnostics
                  </h5>
                  <p class="mb-4 text-base  dark:text-neutral-200">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <button
                    type="button"
                    class="inline-block rounded bg-primary
                     px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal
                      hover:text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="relative">
            <div className="flex  md:flex-row flex-col gap-5">
              <div class="block rounded-lg hover:cursor-zoom-in hover:text-white border-red-500 border-2 border-double hover:bg-red-500 duration-700  bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img class="rounded-t-lg" src={img4} alt="" />
                  <a href="#!">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
                <div class="p-6  ">
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                    Service 04
                  </h5>
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                    Car &amp; Engine Clean
                  </h5>
                  <p class="mb-4 text-base  dark:text-neutral-200">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <button
                    type="button"
                    class="inline-block rounded bg-primary
                     px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal
                      hover:text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    View Details
                  </button>
                </div>
              </div>{" "}
              <div class="block rounded-lg hover:cursor-zoom-in hover:text-white border-red-500 border-2 border-double hover:bg-red-500 duration-700  bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img class="rounded-t-lg" src={img1} alt="" />
                  <a href="#!">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
                <div class="p-6  ">
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                    Service 01
                  </h5>
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                    Electrical System
                  </h5>
                  <p class="mb-4 text-base  dark:text-neutral-200">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <button
                    type="button"
                    class="inline-block rounded bg-primary
                     px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal
                      hover:text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    View Details
                  </button>
                </div>
              </div>
              <div class="block rounded-lg hover:cursor-zoom-in hover:text-white border-red-500 border-2 border-double hover:bg-red-500 duration-700  bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img class="rounded-t-lg" src={img2} alt="" />
                  <a href="#!">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
                <div class="p-6  ">
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                    Service 02
                  </h5>
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                    Auto Car Repair
                  </h5>
                  <p class="mb-4 text-base  dark:text-neutral-200">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <button
                    type="button"
                    class="inline-block rounded bg-primary
                     px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal
                      hover:text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* <div className="bg-black w-full md:my-10">
        <div>
          <div className="text-left md:my-10 my-5">
            <div className="text-Red font-bold text-xl">
              MORE THAN 25+ YEARS EXPERIENCE
            </div>
            <div className="md:text-4xl text-2xl font-bold text-white">
              Make your car feel like a brand new one
            </div>
            <div className="text-white">
              Globally maintain high payoff collaboration and idea sharing after
              viral solution leading are edge mindshare rather than premier
              testing pursue professional customer service revolutinary
              services...
            </div>
            <div className="flex  gap-10 text-white">
              <div className="flex gap-5">
                <div>
                  <img src={feature1} alt="" />
                </div>
                <div className="font-bold text-2xl">
                  Provide Skills <br /> Services
                </div>
              </div>
              <div className=" flex gap-5">
                <div>
                  <img src={feature2} alt="" />
                </div>
                <div className="font-bold text-2xl">
                  Urgent Support <br /> For Clients
                </div>
              </div>
            </div>

            <div>
              <button className="bg-red-500 p-5 rounded-md">
                GET MORE INFO
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {/* content End  */}
    </div>
  );
};

export default SERVICESWEPROVIDE;
