import React from 'react'
import image from '../img/feature.jpg'

const Choose = () => {
  return (
    <div>
        
        <div className=" bg-slate-200 flex md:flex-row flex-col justify-center items-center">
        <div>
            <div className="bg-Red ">
               <img src={image} alt="" className='md:w-[400px] w-96 md:h-full '/>
            </div>
          </div>
      <div className=" max-w-[1280px] flex ">
        <div className="flex md:flex-row flex-col-reverse   gap-32">
          <div className="flex flex-col gap-5 md:my-10 my-5 md:mx-10 mx-5">
            <div className="font-bold text-lg text-Red">WHY CHOOSE US</div>
            <div className="font-bold md:text-4xl text-xl">
            Faster, Safe and Trusted Logistics Services
            </div>
            <div className="text-xl text-justify md:w-[400px] w-80 ">
            Dolores lorem lorem ipsum sit et ipsum. Sadip sea amet diam dolore sed et. Sit rebum labore sit sit ut vero no sit. Et elitr
             stet dolor sed sit et sed ipsum et kasd ut. Erat duo eos et erat sed diam duo
            </div>
            <div className="flex flex-col font-bold gap-5">
              <div>
                {" "}
                <p className="text-Red text-xl   ">Best In Industry</p> 
              </div>
              <div>
                {" "}
                <p className="text-Red text-xl  ">Emergency Services</p> 
              </div>
              <div>
                {" "}
                <p className="text-Red text-xl ">24/7 Customer Support</p> 
              </div>
            </div>
            <button className='bg-Red text-center md:py-2 py-2 md:px-3 px-2 w-fit text-white font-bold'>
                Learn More
            </button>
          </div>


         
        </div>
      </div>
    </div>

    </div>
  )
}

export default Choose