import React from "react";

const Quote = () => {
  return (
    <div className=" bg-slate-200 flex justify-center items-center">
      <div className=" max-w-[1280px] flex ">
        <div className="flex md:flex-row flex-col-reverse   gap-32">
          <div className="flex flex-col gap-5 md:my-10 my-5 md:mx-10 mx-5">
            <div className="font-bold text-lg text-Red">GET A QUOTE</div>
            <div className="font-bold md:text-4xl text-xl">
              Request A Free Quote
            </div>
            <div className="text-xl text-justify md:w-[400px] w-80 ">
              Dolores lorem lorem ipsum sit et ipsum. Sadip sea amet diam dolore
              sed et. Sit rebum labore sit sit ut vero no sit. Et elitr stet
              dolor sed sit et sed ipsum et kasd ut. Erat duo eos et erat sed
              diam duo
            </div>
            <div className="flex md:flex-row flex-col font-bold gap-20">
              <div>
                {" "}
                <p className="text-Red md:text-5xl text-3xl  ">225</p> SKilled Experts
              </div>
              <div>
                {" "}
                <p className="text-Red md:text-5xl text-3xl">1050</p> Happy Clients
              </div>
              <div>
                {" "}
                <p className="text-Red md:text-5xl text-3xl">2500</p> Complete Projects
              </div>
            </div>
          </div>
          <div>
            <div className="bg-Red md:w-[400px] w-96 md:h-full py-20">
                <div className="flex flex-col gap-5 justify-center items-center">

              
              <div>
                <input type="text" placeholder="Your Name" className="md:w-[300px] w-80 md:px-5 px-5 md:py-3 py-2 "/>
              </div>
              <div>
                <input type="text" placeholder="Your Email" className="md:w-[300px] w-80 md:px-5 px-5 md:py-3 py-2 " />
              </div>
              <div>
                <select name="" id="" className="md:w-[300px] w-80 md:px-5 px-5 md:py-3 py-2 ">
                  <option value="Service 1">Service 1</option>
                  <option value="Service 2">Service 2</option>
                  <option value="Service 3">Service 3</option>
                </select>
              </div>
              <div className=" bg-black md:w-[300px] text-center w-80 md:px-5 px-5 md:py-3 py-2 text-white">
                Get A Quote
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
