import React from 'react'

const Pricing = () => {
  return (
    <div>
        <div>
      <div>
        {/* Heders start  */}
        <div className="text-center md:my-10 my-5">
        <div className="text-Red font-bold text-xl">PRICING PLAN</div>
        <div className="md:text-4xl text-2xl font-bold">
        Affordable Pricing Packages
        </div>
      </div>

        {/* Heders END  */}
        <div className="flex justify-center ">
          <div className="max-w-[1280px] flex justify-center md:flex-row flex-col flex-wrap md:my-5 my-5 gap-10">
            <div className=" md:mx-0 mx-5">
              <div className="bg-gray-100 text-black md:w-[400px] w-96">
                <div className="bg-Red text-white flex flex-col gap-2 items-center justify-center font-bold text-lg  md:py-5 py-3 ">
                  <div>O1</div>
                  <div>Basic</div>
                </div>
                <div className="items-center justify-center flex flex-col gap-3 md:py-5 py-3 font-bold text-lg ">
                  <div className="text-4xl">$1000.00</div>
                  <div>
                    {" "}
                    <hr className="w-20 text-5xl" />{" "}
                  </div>
                  <div>variations of</div>
                  <div> passages of Lorem</div>
                  <div> Ipsum available,</div>
                  <div> but the majority</div>
                  <div> have suffered</div>
                  <div> alteration in</div>
                </div>
              </div>
              <div className="items-center justify-center flex flex-col ">
                <button className="bg-Red text-white  md:px-10 px-5  py-2 md:my-10 my-5 rounded-lg">
                  Buy Now{" "}
                </button>
              </div>
            </div>
            <div className="md:mx-0 mx-5">
              <div className="bg-gray-100 text-black md:w-[400px] w-96 ">
                <div className="bg-Red text-white flex flex-col gap-2 items-center justify-center font-bold text-lg  md:py-5 py-3 ">
                  <div>O2</div>
                  <div>STANDARD</div>
                </div>
                <div className="items-center justify-center flex flex-col gap-3 md:py-5 py-3 font-bold text-lg ">
                  <div className="text-4xl">$2000.00</div>
                  <div>
                    {" "}
                    <hr className="w-20 text-5xl" />{" "}
                  </div>
                  <div>variations of</div>
                  <div> passages of Lorem</div>
                  <div> Ipsum available,</div>
                  <div> but the majority</div>
                  <div> have suffered</div>
                  <div> alteration in</div>
                </div>
              </div>
              <div className="items-center justify-center flex flex-col ">
                <button className="bg-Red text-white  md:px-10 px-5  py-2 md:my-10 my-5 rounded-lg">
                  Buy Now{" "}
                </button>
              </div>
            </div>
            <div className="md:mx-0 mx-5">
              <div className="bg-gray-100 text-black md:w-[400px] w-96 ">
                <div className="bg-Red text-white flex flex-col gap-2 items-center justify-center font-bold text-lg  md:py-5 py-3 ">
                  <div>O3</div>
                  <div>PREMIUM</div>
                </div>
                <div className="items-center justify-center flex flex-col gap-3 md:py-5 py-3 font-bold text-lg ">
                  <div className="text-4xl">$3000.00</div>
                  <div>
                    {" "}
                    <hr className="w-20 text-5xl" />{" "}
                  </div>
                  <div>variations of</div>
                  <div> passages of Lorem</div>
                  <div> Ipsum available,</div>
                  <div> but the majority</div>
                  <div> have suffered</div>
                  <div> alteration in</div>
                </div>
              </div>
              <div className="items-center justify-center flex flex-col ">
                <button className="bg-Red text-white  md:px-10 px-5  py-2 md:my-10 my-5 rounded-lg">
                  Buy Now{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
  )
}

export default Pricing