import React from 'react'
import logo from '../Imge/Group 12443.png'
const Footer2 = () => {
  return (

        <div className=' bg-black md:py-10 py-5'>
        <div className='flex md:flex-row flex-col justify-around'>
            <div>
              <img src={logo} alt="" />
            </div>
            <div className=' text-white'>
            Copyright  2023 . All Rights Reserved.
            </div>

        </div>
        </div>
   
  )
}

export default Footer2