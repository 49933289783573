import React from "react";
import Clinet1 from "../images/our.png";
const Testimonials = () => {
  return (
    <div className="bg-blue-500 rounded-3xl">
      <div className="md:my-10 my-5 text-white">
        <div>
          {/* Heders start  */}
          <div>
            <div className="md:py-5 py-2">
              <div className="flex flex-col items-center md:mx-2 mx-5">
                <h1 className="font-bold text-4xl  mx-2 ">Testimonials</h1>

                <div></div>
                <div>
                  nternet. It uses a dictionary of over 200 Latin words,
                  combined with .
                </div>
              </div>
            </div>
          </div>

          {/* Heders END  */}

          <div className="">
            <div className=" flex justify-center">
              <div className="  md:px-10 px-5 md:py-10 py-5  flex flex-col md:flex-row md:my-20 my-10 flex-wrap items-center justify-center gap-20">
                <div>
                  <img src={Clinet1} alt="" className="w-[100px] h-[100px]" />
                </div>
                <div>
                  <div>
                    Luda Johnson <br /> <br />
                    ( Ceo) <hr className="w-20 h-5" />
                  </div>
                  <div>
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 year
                  </div>
                </div>
                <div>
                    Read More ---
                </div>
              </div>

              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
