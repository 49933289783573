import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper";
import img1 from "../Imge/img-5-1-1.jpg";
import img2 from "../Imge/img-12-1-1.jpg";
import img3 from "../Imge/img-9-1-1.jpg";
import img4 from "../Imge/service_1_4.jpg";
import feature1 from "../Imge/feature_1_1.svg";
import feature2 from "../Imge/feature_1_2.svg";

import {AiFillStar,AiFillHeart} from 'react-icons/ai'
import {AiFillEye} from 'react-icons/ai'
import {BsCartPlus} from 'react-icons/bs'

const Products = () => {
  return (
    <div>
      <div>
        {/* Heders start  */}
        <div className="text-center md:my-10 my-5">
          <div className="text-Red font-bold text-xl">SERVICES WE PROVIDE</div>
          <div className="md:text-4xl text-2xl font-bold">Our Service Area</div>
        </div>
      </div>

      {/* content start  */}

      <div
        className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center
     md:flex md:px-8"
      >
        <Swiper 
          navigation={true}
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper ">
          <SwiperSlide className="relative">
            <div className="flex  md:flex-row flex-col gap-5">
              <div class="">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img class="rounded-t-lg" src={img1} alt="" />
                  <a href="">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                     overflow-hidden bg-black  bg-fixed opacity-0 
                     transition duration-300 ease-in-out hover:opacity-100 text-white flex gap-5 justify-center items-center">
                        <AiFillEye className=' text-4xl fill-black bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                        <BsCartPlus className=' text-4xl fill-black bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                        <AiFillHeart className=' text-4xl fill-red-500 bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                     </div>
                  </a>
                </div>
                <div class="p-6 text-center  ">
                  <h5 class="mb-2 text-xl font-medium leading-tight flex justify-center items-center gap-2  dark:text-neutral-50">
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                  </h5>
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                  Allion Brake Pad
                  </h5>
                  <p class="mb-4 text-base flex justify-center items-center gap-5  dark:text-neutral-200">
                    <p>
                    $ 136.00      
                    </p>
                    <p className='line-through'>
                        $ 180.00
                    </p>
                   
                  </p>
               
                </div>
              </div>
              <div class="">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img class="rounded-t-lg" src={img2} alt="" />
                  <a href="">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                     overflow-hidden bg-black  bg-fixed opacity-0 
                     transition duration-300 ease-in-out hover:opacity-100 text-white flex gap-5 justify-center items-center">
                        <AiFillEye className=' text-4xl fill-black bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                        <BsCartPlus className=' text-4xl fill-black bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                        <AiFillHeart className=' text-4xl fill-red-500 bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                     </div>
                  </a>
                </div>
                <div class="p-6 text-center  ">
                  <h5 class="mb-2 text-xl font-medium leading-tight flex justify-center items-center gap-2  dark:text-neutral-50">
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className=''/>
                  </h5>
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                  Audi Wheel Combo
                  </h5>
                  <p class="mb-4 text-base flex justify-center items-center gap-5  dark:text-neutral-200">
                    <p>
                    $ 136.00      
                    </p>
                    <p className='line-through'>
                        $ 180.00
                    </p>
                   
                  </p>
               
                </div>
              </div>
              <div class="">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img class="rounded-t-lg" src={img3} alt="" />
                  <a href="">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                     overflow-hidden bg-black  bg-fixed opacity-0 
                     transition duration-300 ease-in-out hover:opacity-100 text-white flex gap-5 justify-center items-center">
                        <AiFillEye className=' text-4xl fill-black bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                        <BsCartPlus className=' text-4xl fill-black bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                        <AiFillHeart className=' text-4xl fill-red-500 bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                     </div>
                  </a>
                </div>
                <div class="p-6 text-center  ">
                  <h5 class="mb-2 text-xl font-medium leading-tight flex justify-center items-center gap-2  dark:text-neutral-50">
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className=''/>
                  </h5>
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                  BMW Brake Liver
                  </h5>
                  <p class="mb-4 text-base flex justify-center items-center gap-5  dark:text-neutral-200">
                    <p>
                    $ 206.00      
                    </p>
                    <p className='line-through'>
                        $ 80.00
                    </p>
                   
                  </p>
               
                </div>
              </div>
            
         
            </div>
          </SwiperSlide>
          <SwiperSlide className="relative">
          <div className="flex  md:flex-row flex-col gap-5">
              <div class="">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img class="rounded-t-lg" src={img1} alt="" />
                  <a href="">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                     overflow-hidden bg-black  bg-fixed opacity-0 
                     transition duration-300 ease-in-out hover:opacity-100 text-white flex gap-5 justify-center items-center">
                        <AiFillEye className=' text-4xl fill-black bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                        <BsCartPlus className=' text-4xl fill-black bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                        <AiFillHeart className=' text-4xl fill-red-500 bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                     </div>
                  </a>
                </div>
                <div class="p-6 text-center  ">
                  <h5 class="mb-2 text-xl font-medium leading-tight flex justify-center items-center gap-2  dark:text-neutral-50">
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                  </h5>
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                  Allion Brake Pad
                  </h5>
                  <p class="mb-4 text-base flex justify-center items-center gap-5  dark:text-neutral-200">
                    <p>
                    $ 136.00      
                    </p>
                    <p className='line-through'>
                        $ 180.00
                    </p>
                   
                  </p>
               
                </div>
              </div>
              <div class="">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img class="rounded-t-lg" src={img2} alt="" />
                  <a href="">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                     overflow-hidden bg-black  bg-fixed opacity-0 
                     transition duration-300 ease-in-out hover:opacity-100 text-white flex gap-5 justify-center items-center">
                        <AiFillEye className=' text-4xl fill-black bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                        <BsCartPlus className=' text-4xl fill-black bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                        <AiFillHeart className=' text-4xl fill-red-500 bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                     </div>
                  </a>
                </div>
                <div class="p-6 text-center  ">
                  <h5 class="mb-2 text-xl font-medium leading-tight flex justify-center items-center gap-2  dark:text-neutral-50">
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className=''/>
                  </h5>
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                  Audi Wheel Combo
                  </h5>
                  <p class="mb-4 text-base flex justify-center items-center gap-5  dark:text-neutral-200">
                    <p>
                    $ 136.00      
                    </p>
                    <p className='line-through'>
                        $ 180.00
                    </p>
                   
                  </p>
               
                </div>
              </div>
              <div class="">
                <div
                  class="relative overflow-hidden bg-cover bg-no-repeat"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img class="rounded-t-lg" src={img3} alt="" />
                  <a href="">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full
                     overflow-hidden bg-black  bg-fixed opacity-0 
                     transition duration-700  ease-in-out hover:opacity-100 text-white flex gap-5 justify-center items-center">
                        <AiFillEye className=' text-4xl fill-black bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                        <BsCartPlus className=' text-4xl fill-black bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                        <AiFillHeart className=' text-4xl fill-red-500 bg-white w-16 h-16 p-2  rounded-full flex justify-center items-center' />
                     </div>
                  </a>
                </div>
                <div class="p-6 text-center  ">
                  <h5 class="mb-2 text-xl font-medium leading-tight flex justify-center items-center gap-2  dark:text-neutral-50">
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className='fill-orange-400'/>
                 <AiFillStar className=''/>
                  </h5>
                  <h5 class="mb-2 text-xl font-medium leading-tight  dark:text-neutral-50">
                  BMW Brake Liver
                  </h5>
                  <p class="mb-4 text-base flex justify-center items-center gap-5  dark:text-neutral-200">
                    <p>
                    $ 206.00      
                    </p>
                    <p className='line-through'>
                        $ 80.00
                    </p>
                   
                  </p>
               
                </div>
              </div>
            
         
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      
      {/* content End  */}
    </div>
  )
}

export default Products