import React from "react";
import { IoLogoFacebook, IoLogoTwitter, IoLogoLinkedin } from "react-icons/io";
import Team1 from "../Imge/team_1_1.jpg";
import Team2 from "../Imge/team_1_2.jpg";
import Team3 from "../Imge/team_1_3.jpg";
import Team4 from "../Imge/team_1_4.jpg";
const Techniciant = () => {
  return (
    <div className="md:my-20 my-5">
      <div>
        {/* Heders start  */}
        <div className="text-center md:my-10 my-5">
          <div className="text-Red font-bold text-xl">EXPERT TECHNICIAN</div>
          <div className="md:text-4xl text-2xl font-bold">Meet Our Experts</div>
        </div>
      </div>

      {/* contet  */}
      <div className="justify-center items-center flex md:my-20 my-5">
        <div className="max-w-[1280px]">
          <div className="flex md:flex-row flex-col gap-10">
            <div>
              <div
                class="relative overflow-hidden bg-cover bg-no-repeat"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                <img
                  class="rounded-t-lg object-cover h-96 md:w-[900px] w-96 md:h-[500px] "
                  src={Team1}
                  alt=""
                />
                <a href="">
                  <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                </a>
              </div>
              <div className=" shadow-2xl md:w-[300px] text-center md:hidden block bg-white text-black hover:text-red-500 p-10">
                <h5 class="mb-2 text-xl font-medium leading-tight ">
                  Kevin Martin
                </h5>
                <p>Engine Expert</p>
              </div>
              <div class="p-6 flex justify-center">
                <div className=" md:w-[300px] shadow-2xl text-center absolute md:-mt-32 bg-white text-black hover:text-red-500 p-10">
                  <h5 class="mb-2 text-xl font-medium leading-tight ">
                    Kevin Martin
                  </h5>
                  <p>Engine Expert</p>
                </div>
                <br />
                <hr />
                <br />
                <div className=" shadow-2xl md:w-[300px] absolute flex hover:bg-black duration-500 items-center justify-center  bg-white text-black hover:text-red-500 p-10">
                  <div className="flex gap-5">
                    <IoLogoFacebook className="text-4xl" />
                    <IoLogoTwitter className="text-4xl" />
                    <IoLogoLinkedin className="text-4xl" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                class="relative overflow-hidden bg-cover bg-no-repeat"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                <img
                  class="rounded-t-lg object-cover h-96 md:w-[900px] w-96 md:h-[500px] "
                  src={Team2}
                  alt=""
                />
                <a href="">
                  <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                </a>
              </div>
              <div className=" md:w-[300px] text-center shadow-2xl md:hidden block bg-white text-black hover:text-red-500 p-10">
                <h5 class="mb-2 text-xl font-medium leading-tight ">
                  Jhon Sena
                </h5>
                <p>Engine Expert</p>
              </div>
              <div class="p-6 flex justify-center">
                <div className=" md:w-[300px] text-center shadow-2xl absolute md:-mt-32 bg-white text-black hover:text-red-500 p-10">
                  <h5 class="mb-2 text-xl font-medium leading-tight ">
                    Jhon Sena
                  </h5>
                  <p>Engine Expert</p>
                </div>
                <br />
                <hr />
                <br />
                <div className=" md:w-[300px] shadow-2xl absolute flex hover:bg-black duration-500 items-center justify-center  bg-white text-black hover:text-red-500 p-10">
                  <div className="flex gap-5">
                    <IoLogoFacebook className="text-4xl" />
                    <IoLogoTwitter className="text-4xl" />
                    <IoLogoLinkedin className="text-4xl" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                class="relative overflow-hidden bg-cover bg-no-repeat"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                <img
                  class="rounded-t-lg object-cover h-96 md:w-[900px] w-96 md:h-[500px] "
                  src={Team3}
                  alt=""
                />
                <a href="">
                  <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                </a>
              </div>
              <div className=" md:w-[300px] shadow-2xl text-center md:hidden block bg-white text-black hover:text-red-500 p-10">
                <h5 class="mb-2 text-xl font-medium leading-tight ">
                  JR Shawon
                </h5>
                <p>Engine Expert</p>
              </div>
              <div class="p-6 flex justify-center">
                <div className=" md:w-[300px] shadow-2xl text-center absolute md:-mt-32 bg-white text-black hover:text-red-500 p-10">
                  <h5 class="mb-2 text-xl font-medium leading-tight ">
                    JR Shawon
                  </h5>
                  <p>Engine Expert</p>
                </div>
                <br />
                <hr />
                <br />
                <div className=" md:w-[300px] shadow-2xl absolute flex hover:bg-black duration-500 items-center justify-center  bg-white text-black hover:text-red-500 p-10">
                  <div className="flex gap-5">
                    <IoLogoFacebook className="text-4xl" />
                    <IoLogoTwitter className="text-4xl" />
                    <IoLogoLinkedin className="text-4xl" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Header start  */}
      <div className="selection:bg-Green selection:text-white mx-auto 
      w-full md:h-[20rem]  h-96 py-10 px-4 bg-Serv bg-cover md:bg-top bg-center">
        {/* Content Section */}
        <div className="flex md:flex-row md:mx-10 mx-5 flex-col gap-10 
        justify-center text-left items-center md:h-[15rem] h-96 mx-auto">
         
          <h1 className="text-white uppercase font-bold py-2 md:text-3xl text-xl mb-3">
        
            <p className="text-red-500 font-bold">GET OUR SERVICE</p>
            Get Premium Auto Car Service Feel Free To Contact Us.
          </h1>
          <p className=" text-center text-white font-subheading   md:text-lg text-xs md:w-[980px] max-w-fit">
            <div className="flex gap-10">
              <button className="bg-red-500 hover:bg-white font-bold
               hover:text-black p-5 rounded-lg">GET OUR SERVICE</button>
              <button className="bg-white hover:bg-red-500 font-bold hover:text-white text-black p-5 rounded-lg">CONTACT US</button>
            </div>
          </p>
        </div>
        {/* Content Section Ends*/}
      </div>

      {/* Header End  */}
    </div>
  );
};

export default Techniciant;
