import React from 'react'
import test1 from '../Imge/testi_1_1.jpg'
import test2 from '../Imge/testi_1_3.jpg'
import test3 from '../Imge/testi_1_2.jpg'
const Testmonal = () => {
  return (
<div>
<div className="flex justify-center md:my-20 my-10">
        <div className="max-w-[1280px] flex justify-center md:flex-row flex-col flex-wrap md:my-5 my-5 gap-10">
          <div className=" md:mx-0 mx-5">
            <div className="  ">
              <div className="items-center justify-center flex flex-col 
              shadow-2xl bg-white md:w-[350px] w-96 gap-3 
               text-lg ">
                <div>
                    <img src={test1} alt="" className='rounded-full md:-mt-16' />
                </div>
                <p className='mx-10 text-justify text-lg my-5'>
                “Intrinsicly pontifice pandemic ships
                growth strategies and partner lives
                multidi sciplinar business supply
                strategic holistic scale via focused
                convergence provide access to mission
                Ritical customer service”
   
                </p>
       
              </div>
              <div
                className="bg-Red text-white flex flex-col gap-2 items-center 
                justify-center font-bold text-lg  md:py-5 py-3 "
              >
                <div>Rubaida Kanom</div>
                <div>Chef Leader</div>
              </div>
            </div>
          </div>
          <div className=" md:mx-0 mx-5">
            <div className="  ">
              <div className="items-center justify-center flex flex-col 
              shadow-2xl bg-white md:w-[350px] w-96 gap-3 
               text-lg ">
                <div>
                    <img src={test2} alt="" className='rounded-full md:-mt-16' />
                </div>
                <p className='mx-10 text-justify text-lg my-5'>
                “Intrinsicly pontifice pandemic ships
                growth strategies and partner lives
                multidi sciplinar business supply
                strategic holistic scale via focused
                convergence provide access to mission
                Ritical customer service”
   
                </p>
       
              </div>
              <div
                className="bg-Red text-white flex flex-col gap-2 items-center 
                justify-center font-bold text-lg  md:py-5 py-3 "
              >
                <div>Redwan Hossain
</div>
                <div>Chef Leader</div>
              </div>
            </div>
          </div>
          <div className=" md:mx-0 mx-5">
            <div className="  ">
              <div className="items-center justify-center flex flex-col 
              shadow-2xl bg-white md:w-[350px] w-96 gap-3 
               text-lg ">
                <div>
                    <img src={test3} alt="" className='rounded-full md:-mt-16' />
                </div>
                <p className='mx-10 text-justify text-lg my-5'>
                “Intrinsicly pontifice pandemic ships
                growth strategies and partner lives
                multidi sciplinar business supply
                strategic holistic scale via focused
                convergence provide access to mission
                Ritical customer service”
   
                </p>
       
              </div>
              <div
                className="bg-Red text-white flex flex-col gap-2 items-center 
                justify-center font-bold text-lg  md:py-5 py-3 "
              >
                <div>Alex Pulk
</div>
                <div>Managing Director</div>
              </div>
            </div>
          </div>

        
       
        </div>
      </div>


      <div className="md: selection:bg-Green selection:text-white mx-auto 
      w-full md:h-[15rem]  h-96 py-10 px-4 bg-Serv bg-cover md:bg-top bg-center md:-mt-52">
        {/* Content Section */}
    
        {/* Content Section Ends*/}
      </div>
</div>
  )
}

export default Testmonal