import React from "react";
import image from "../Imge/video_1-1-1.jpg";
import Fe1 from "../Imge/feature_2_1.svg";
import Fe2 from "../Imge/feature_2_2.svg";
import Fe3 from "../Imge/feature_2_3.svg";
import Fe4 from "../Imge/feature_2_4.svg";
import Fe5 from "../Imge/feature_2_5.svg";
import Fe6 from "../Imge/feature_2_6.svg";
const Choose = () => {
  return (
    <div className="flex justify-center items-center">
      <div className=" flex md:flex-row flex-col gap-10">
        <div className="">
          <img
            src={image}
            alt=""
            className="md:w-[650px] md:h-[500px] w-96 h-96 hover:scale-105 transition-all duration-500 cursor-pointer"
          />
        </div>
        <div className="flex flex-col justify-center">
          <div className="text-red-500 font-bold">CORE FEATURES</div>
          <div className="font-bold text-4xl">Why Choose Us</div>
          {/* image  */}
          <div className="flex md:flex-row flex-col gap-10 md:my-10 my-5">
            <div className="bg-black md:py-8 py-5 md:px-10 px-3 text-white flex gap-5 flex-col justify-center items-center">
              <p>
                <img src={Fe1} alt="" />
              </p>
              <p className="font-bold text-sm whitespace-nowrap">
                Expert Teams
              </p>
            </div>
            <div className="bg-black md:py-8 py-5 md:px-10 px-3 text-white flex gap-5 flex-col justify-center items-center">
              <p>
                <img src={Fe2} alt="" />
              </p>
              <p className="font-bold text-sm whitespace-nowrap">
                Timely Delivery
              </p>
            </div>
            <div className="bg-black md:py-8 py-5 md:px-10 px-3 text-white flex gap-5 flex-col justify-center items-center">
              <p>
                <img src={Fe3} alt="" />
              </p>
              <p className="font-bold text-sm whitespace-nowrap">
                24/7 Support
              </p>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-10 md:my-10 my-5">
            <div className="bg-black md:py-8 py-5 md:px-10 px-3 text-white flex gap-5 flex-col justify-center items-center">
              <p>
                <img src={Fe4} alt="" />
              </p>
              <p className="font-bold text-sm whitespace-nowrap">
                Best Equipment
              </p>
            </div>
            <div className="bg-black md:py-8 py-5 md:px-10 px-3 text-white flex gap-5 flex-col justify-center items-center">
              <p>
                <img src={Fe5} alt="" />
              </p>
              <p className="font-bold text-sm whitespace-nowrap">
                100% Guranty
              </p>
            </div>
            <div className="bg-black md:py-8 py-5 md:px-10 px-3 text-white flex gap-5 flex-col justify-center items-center">
              <p>
                <img src={Fe6} alt="" />
              </p>
              <p className="font-bold text-sm whitespace-nowrap">
                Timely Delivery
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choose;
