import React from "react";
import Abo from "../images/about.png";
const About = () => {
  return (
    <div>
      <div className="flex md:flex-row flex-col items-center justify-center
       gap-20 md:my-10 my-5 md:mx-10 mx-5">
        <div>
          <img src={Abo} alt="" className="md:w-[500px] w-96   object-cover" />
        </div>

        <div
          className="flex flex-col justify-center  items-center
        "
        >
          <div className="flex md:flex-row flex-col ">
            <div className="flex flex-col gap-5">
              <div className="font-bold md:text-7xl text-3xl text-blue-500">
                About Us
              </div>
              <div className="font-bold text-sm md:w-[400px] w-64 ">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, There
              </div>
              <div className="text-bold text-2xl">
                Read More -----
                <hr className="w-20 h-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
