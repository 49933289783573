import React from "react";
import Logo from "../images/Group 8295.png";
import Menu from "../images/menu_icon.png";
import Truck from "../images/track.png";
const Navbar = () => {
  return (
    <div>
      {/* Header start  */}
      <div
        className="selection:bg-Green selection:text-white mx-auto w-full
       md:h-[50rem]  h-[65rem] py-10 px-4 md:bg-Logost bg-gray-200 bg-contain bg-no-repeat 
       md:bg-right bg-center"
      >
        <div className="flex   justify-between items-center md:mx-5 mx-10 ">
          <div className="flex gap-5 text-Red font-bold text-4xl">
            <p>
              <img src={Logo} alt="" className=" object-cover" />
            </p>
          </div>

          <div>
            <p>
              <img src={Menu} alt="" className=" object-cover" />
            </p>
          </div>
        </div>
        {/* Content Section */}
        <div>
          <div
            className="flex flex-col justify-center  items-center
         md:h-[40rem] h-[20rem] md:my-10 my-80 mx-auto"
          >
            <div className="flex md:flex-row flex-col gap-10">
              <div className="flex flex-col gap-5">
                <div className="font-bold md:text-7xl text-3xl text-blue-500">
                  Best Logistic <br /> Company
                </div>
                <div className="font-bold text-sm">
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has i
                </div>
                <div className="text-bold text-2xl">
                  Contact Us -----
                  <hr className="w-20 h-5" />
                </div>
              </div>
              <div>
                <img src={Truck} alt="" />
              </div>
              <div>
                <div className=" text-bold text-2xl">Professional Services</div>
                <div className=" text-bold text-2xl md:my-5 my-3">
                  Get your transport quote
                  <div className="flex flex-col gap-5  md:my-5 my-3">
                    <div>
                      <input type="text" placeholder="Location" />
                    </div>
                    <div>
                      <input type="text" placeholder="To destination" />{" "}
                    </div>
                    <div>
                      <input type="text" placeholder="Email" />{" "}
                    </div>
                    <div>
                      <input type="text" placeholder="Contact Number" />{" "}
                    </div>
                    <div className="flex w-52 text-center items-center text-lg">
                      <buthon
                        type="submit"
                        className=" p-3 flex w-52 text-center items-center text-lg bg-black text-white"
                      >
                        Get Now quote
                      </buthon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Content Section Ends*/}
      </div>

      {/* Header End  */}

      <div></div>
    </div>
  );
};

export default Navbar;
