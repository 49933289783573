import React from "react";
import { Helmet } from "react-helmet";
import Topbar from "./Componets/Topbar";
import Navbar from "./Componets/Navbar";
import Home from "./Componets/Home";
import Service from "./Componets/Service";
import SERVICESWEPROVIDE from "./Componets/SERVICESWEPROVIDE";
import Techniciant from "./Componets/Techniciant";
import Choose from "./Componets/Choose";
import Testmonal from "./Componets/Testmonal";
import Products from "./Componets/Products";
import Blog from "./Componets/Blog";
import Footer from "./Componets/Footer";
import Footer2 from "./Componets/Footer2";

const Car = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Transport || Car </title>
      </Helmet>
      <div>
        <Topbar />
        <Navbar />
        <Home />
        <Service />
        <SERVICESWEPROVIDE />
        <Techniciant />
        <Choose />
        <Testmonal />
        <Products />
        <Blog />
        <Footer />
        <Footer2 />

      </div>
    </div>
  );
};

export default Car;
