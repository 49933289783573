import React from "react";
import img1 from "../images/ser1.png";
import img2 from "../images/ser2.png";
import img3 from "../images/ser3.png";
import img4 from "../images/ser4.png";
import img5 from "../images/ser5.png";

const Services = () => {
  return (
    <div>
      <div className="bg-gray-100">
        <div className="md:py-5 py-2">
          <div className="flex flex-col items-center md:mx-2 mx-5">
            <h1 className="font-bold text-4xl  mx-2 text-blue-500">
              Our Services{" "}
            </h1>

            <div></div>
            <div>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered <br /> alteration in some form, by
              injected humour, There
            </div>
          </div>
        </div>

        {/* contet  */}

        <div className="flex md:flex-row flex-col justify-center items-center gap-10 md:py-10 py-5 ">
          <div data-aos="fade-down" className="flex flex-col items-center">
            <div  className="bg-blue-500 rounded-full w-52 h-52 flex items-center justify-center ">
              <img src={img1} alt="" className="" />
            </div>
            <div>FLY ANYWHERE</div>
          </div>
          <div data-aos="fade-up"  className="flex flex-col items-center">
            <div className="bg-amber-600 rounded-full w-52 h-52 flex items-center justify-center ">
              <img src={img2} alt="" />
            </div>
            <div>Cargo service</div>
          </div>
          <div data-aos="fade-down"  className="flex flex-col items-center">
            <div className="bg-amber-300 rounded-full w-52 h-52 flex items-center justify-center ">
              <img src={img3} alt="" />
            </div>
            <div>COURIER SERVICES</div>
          </div>
          <div data-aos="fade-up"  className="flex flex-col items-center">
            <div className="bg-amber-600 rounded-full w-52 h-52 flex items-center justify-center ">
              <img src={img4} alt="" />
            </div>
            <div>BOX STORAGE</div>
          </div>
          <div data-aos="fade-down"  className="flex flex-col items-center">
            <div className="bg-amber-300 rounded-full w-52 h-52 flex items-center justify-center ">
              <img src={img5} alt="" />
            </div>
            <div>100% safe</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
