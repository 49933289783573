import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation, Autoplay, Pagination } from "swiper";

import imge1 from "../Imge/hero_bg_1_1-2.jpg";
import imge2 from "../Imge/hero_bg_1_2-2.jpg";
import imge3 from "../Imge/hero_bg_1_3-1.jpg";

const Home = () => {
  return (
    <div>
      <div className="justify-between px-4 mx-auto w-full
      md:items-center md:flex md:px-8">
        <Swiper
          navigation={true}
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper "
        >
          <SwiperSlide className="relative">
            <img
              className="object-fill w-full md:h-[600px] h-96"
              src={imge1}
              alt="image slide 1"
            />
            <div
              data-aos="fade-up"
              class=" hover:bg-red-500 hover:text-white rounded-xl md:p-10 p-5
               border-red-500 border-2
             absolute    text-5xl text-white md:top-[200px] md:left-[300px] top-[200px] left-[150px] -translate-x-1/2 -translate-y-1/2"
            >
              <small className="text-lg uppercase">Car - repair - Center</small>{" "}
              <br />
              <p className="font-bold md:text-5xl text-xl">
                {" "}
                Best Affordable <br /> Car Repair
                <br /> Service Center{" "}
              </p>{" "}
              <br />
              <div className=" flex gap-10">
                <button className="text-lg bg-red-500 text-white p-3 rounded-lg hover:bg-white hover:text-black">
                  About us{" "}
                </button>
                <button className="text-lg bg-red-500 text-white p-3 rounded-lg hover:bg-white hover:text-black">
                  Project{" "}
                </button>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="relative">
            <img
              className="object-fill w-full md:h-[600px] h-96"
              src={imge2}
              alt="image slide 1"
            />
            <div
              data-aos="fade-up"
              class=" hover:bg-red-500 hover:text-white rounded-xl md:p-10 p-5 border-red-500 border-2
             absolute    text-5xl text-white md:top-[200px] md:left-[200px] top-[200px] left-[150px] -translate-x-1/2 -translate-y-1/2"
            >
              <small className="text-lg uppercase">Car - repair - Center</small>{" "}
              <br />
              <p className="font-bold md:text-5xl text-xl">
                {" "}
                Best Affordable <br /> Car Repair
                <br /> Service Center{" "}
              </p>{" "}
              <br />
              <div className=" flex gap-10">
                <button className="text-lg bg-red-500 text-white p-3 rounded-lg hover:bg-white hover:text-black">
                  About us{" "}
                </button>
                <button className="text-lg bg-red-500 text-white p-3 rounded-lg hover:bg-white hover:text-black">
                  Project{" "}
                </button>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="relative">
            <img
              className="object-fill w-full md:h-[600px] h-96"
              src={imge3}
              alt="image slide 1"
            />
            <div
              data-aos="fade-up"
              class=" hover:bg-red-500 hover:text-white rounded-xl md:p-10 p-5 border-red-500 border-2
             absolute    text-5xl text-white md:top-[200px] md:left-[200px] top-[200px] left-[150px] -translate-x-1/2 -translate-y-1/2"
            >
              <small className="text-lg uppercase">
                Car - fixding - Center
              </small>{" "}
              <br />
              <p className="font-bold md:text-5xl text-xl">
                {" "}
                Fasted Service <br />
                Within Short
                <br /> Time Care{" "}
              </p>{" "}
              <br />
              <div className=" flex gap-10">
                <button className="text-lg bg-red-500 text-white p-3 rounded-lg hover:bg-white hover:text-black">
                  About us{" "}
                </button>
                <button className="text-lg bg-red-500 text-white p-3 rounded-lg hover:bg-white hover:text-black">
                  Project{" "}
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Home;
