import React from "react";
import Logo from '../img/MorRex.png';
const Navbar = () => {
  return (
    <div>
      <div className="flex justify-between items-center md:mx-5 mx-10 ">
        <div className="flex gap-5 text-Red font-bold text-4xl">
      
              <p><img src={Logo} alt="" className="md:w-10 md:h-10 w-10 h-10 object-cover" />
                </p>   
         <p>MorRex
          </p> 
    
     
        </div>
        <div className="md:block hidden">
          <ul className=" flex justify-around gap-10  text-xl  ">
            <li className="hover:bg-Red bg-Red md:py-5 px-2">Home</li>
            <li className="md:py-5 px-2 hover:bg-Red">About</li>
            <li className="md:py-5 px-2 hover:bg-Red">Service</li>
            <li className="md:py-5 px-2 hover:bg-Red">Price</li>
            <li className="md:py-5 px-2 hover:bg-Red">Pages</li>
            <li className="md:py-5 px-2 hover:bg-Red">Contact</li>
          </ul>
        </div>
        <div>
          <button className="bg-Red md:px-2  font-bold  px-2 py-2 md:text-xl text-sm rounded-lg">Get A Quots</button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
