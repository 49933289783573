import React from "react";
import {BiPhoneCall} from 'react-icons/bi'
import {AiOutlineMail} from 'react-icons/ai'
import {GoLocation} from 'react-icons/go'
const Footer = () => {
  return (
    <>
      <div className="bg-black text-white">
        <div className="flex justify-center items-center">

   
        <div className="flex md:flex-row justify-around flex-col">
          <div className="w-80">
            <h1 className="font-extrabold text-2xl md:my-5 my-5">ABOUT COMPANY</h1>
            <ul className="flex flex-col gap-5 font-bold mx-5">
              <li className=" text-left">
                Centric aplications productize before front end vortals
                visualize front end is results and value added
              </li>
              <li  className="font-bold text-lg">WE ARE AVAILABLE</li>
              <li>Mon-Sat: 09.00 am to 6.30 pm</li>
            </ul>
          </div>
          <div className="w-80">
            <h1 className="font-extrabold text-2xl md:my-5 my-5">QUICK LINK</h1>

            <ul className="flex flex-col gap-5 font-bold">
              <li>About Company</li>
              <li>Work Gallery</li>
              <li>Our Services</li>
              <li>Our Team</li>
              <li>Latest Blog</li>
              <li>Contact Us</li>
            </ul>
          </div>
          <div className="w-80">
            <h1 className="font-extrabold text-2xl md:my-5 my-5"> RECENT POSTS </h1>

            <ul className="flex flex-col gap-5 font-bold">
              <li>Jul 16 2022</li>
              <li>Sustainable strategic theme areas</li>
              <br />
              <li>Jun 16 2022</li>
              <li>Objectively excellent scenarios attempt</li>
            </ul>
          </div>
          <div className="w-80">
            <h1 className="font-extrabold text-2xl md:my-5 my-5"> CONTACT DETAILS</h1>
            <ul className="flex flex-col gap-5">
              <li className="font-bold text-lg">PHONE NUMBER</li>
              <li className="flex gap-2">
                <span>
                  <BiPhoneCall className="bg-red-500 fill-white w-8 h-8 p-2 rounded-xl"/>
                  
                 
                </span> <span>+91 9826906988</span>
              </li>
              <li className="font-bold text-lg">EMAIL ADDRESS</li>
              <li className="flex gap-2">
                <span>
                <AiOutlineMail className="bg-red-500 fill-white w-8 h-8 p-2 rounded-xl"/>
                
                
                </span><span>career@cerbosys.com</span>
              </li>
              <li className="font-bold text-lg">OFFICE LOCATION</li>
              <li className="flex gap-2">
                <span>
                 <GoLocation  className="bg-red-500 fill-white w-8 h-8 p-2 rounded-xl"/>
                
                </span> <span>15, Kanadia Main Rd, Sanchar Nagar Ext, Lakshya Vihar,
                     Indore, Madhya Pradesh 452016</span>
              </li>
            </ul>
          </div>
        </div>     </div>
      </div>
    </>
  );
};

export default Footer;
