import React from 'react'
import Navbar from './Commponets/Navbar'
import Heders from './Commponets/Heders'
import About from './Commponets/About'
import Quote from './Commponets/Quote'
import SERVICES from './Commponets/SERVICES'
import Choose from './Commponets/Choose'
import Pricing from './Commponets/Pricing'
import Team from './Commponets/Team'
import TESTIMONIAL from './Commponets/TESTIMONIAL'
import Blogs from './Commponets/Blogs'
import Footer from './Commponets/Footer'
import { Helmet } from 'react-helmet'

const Morrex = () => {
  return (
    <>
    
    <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Transport || Morrex </title>
      </Helmet>
    <div>
        <Navbar/>
        <Heders/>
        <About/>
        <Quote/>
        <SERVICES/>
        <Choose/>
        <Pricing/>
        <Team/>
        <TESTIMONIAL/>
        <Blogs/>
        <Footer/>
    </div> </>
  )
}

export default Morrex