import logo from './logo.svg';
import './App.css';
import { BrowserRouter  ,  Routes,Route} from "react-router-dom";
import Morrex from './Morrex/Morrex';
import Logisolution from './Logisolution/Logisolution';
import Transport from './Transport';
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import Car from './Car/Car';
import Notfound from './Notfound';
function App() {
  useEffect(() => {
    Aos.init({
      duration: 1800,
      offset: 100,
      disable: "mobile",
    });
  }, []);
  return (
    <div className="">

<BrowserRouter>
<Routes>
  <Route path='/' element={<Transport/>}/>
  <Route path='/shipping' element={<Morrex/>}/>
  <Route path='/transportation' element={<Logisolution/>}/>
  <Route path='/car-service-world' element={<Car/>}/>
  <Route path="*" element={<Notfound />} />

</Routes>
</BrowserRouter>

   
    </div>
  );
}

export default App;
