import React from "react";
import Servic from "../Imge/servic.png";
import contect from "../Imge/contact_1_1.svg";
import contect2 from "../Imge/contact_1_2.svg";
import contect3 from "../Imge/contact_1_3.svg";
const Service = () => {
  return (
    <div className="bg-slate-100 md:py-10 py-5">
      <div className="flex md:flex-row flex-col justify-center items-center gap-32">
        <div>
          <div
            className="md:w-[250px] md:h-[200px] w-96 h-96 bg-red-500 absolute  text-white text-2xl
           flex justify-center items-center md:px-10 md:py-10 md:my-16 md:-mx-4 font-bold "
          >
            <div>
              <h1 className="text-6xl">31+</h1>
              <p>
                YEARS OF <br /> EXPERIENCED
              </p>
            </div>
          </div>

          <img src={Servic} alt="" />
        </div>
        <div className="flex flex-col gap-10 items-center justify-center">
          <div className="text-sm font-bold text-red-500">NEED OUR SERVICE</div>
          <div className="md:text-5xl text-xl font-bold">Make Appointment</div>

          <div className="flex flex-col gap-10 items-center justify-center">
            <div>
              <input
                type="text"
                placeholder="Enter Your Name"
                className=" 
              bg-white shadow-2xl border-none
              md:w-[500px] w-96 md:px-2 md:py-4 rounded-lg"
              />
            </div>
            <div className="flex md:flex-row flex-col gap-10">
              <input
                type="text"
                placeholder="Email Address"
                className=" w-96   bg-white shadow-2xl border-none
              md:w-[230px] md:px-2 md:py-4 rounded-lg"
              />
              <select
                name=""
                id=""
                className=" 
              bg-white shadow-2xl border-none
              md:w-[230px] w-96 md:px-2 md:py-4 rounded-lg"
              >
                <option value="1">Electricla system</option>
                <option value="1">Electricla system</option>
                <option value="1">Electricla system</option>
                <option value="1">Electricla system</option>
                <option value=""></option>
              </select>
            </div>
            <div className="flex md:flex-row flex-col gap-10">
              <input
                type="date"
                placeholder="Date "
                className="   bg-white shadow-2xl border-none
              md:w-[230px] w-96 md:px-2 md:py-4 rounded-lg"
              />
              <input
                type="time"
                placeholder="Select time"
                className=" w-96   bg-white shadow-2xl border-none
              md:w-[230px] md:px-2 md:py-4 rounded-lg"
              />
            </div>
            <div>
              <textarea
                rows="5"
                placeholder="Message"
                className=" md:w-[500px]  w-96 bg-white shadow-2xl border-none
             rounded-lg"
              />
            </div>
            <div>
              <button
                className="bg-red-500  text-white hover:bg-white
                 hover:text-black md:px-5 md:py-3 py-3 px-3 rounded-xl font-bold"
              >
                Get A Quote
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex md:flex-row flex-col  justify-center items-center md:my-20 my-10 ">
        <div
          className="flex md:flex-row flex-col gap-5 justify-center
         bg-black text-white items-center md:px-20 md:py-10 py-10 px-10 w-full"
        >
          <div>
            <img src={contect} alt="" />
          </div>
          <div className="flex flex-col">
            <p>WE ARE OPEN MONDAY-FRIDAY</p>
            <p>7:00 am - 9:00 pm</p>
          </div>
        </div>
        <div
          className="flex md:flex-row flex-col gap-5 justify-center
        md:px-20 md:py-10 py-10 px-10 bg-Red text-white items-center w-full"
        >
          <div>
            <img src={contect2} alt="" />
          </div>
          <div className="flex flex-col">
            <p>WE ARE OPEN MONDAY-FRIDAY</p>
            <p>7:00 am - 9:00 pm</p>
          </div>
        </div>
        <div
          className="flex md:flex-row flex-col gap-5 justify-center
        md:px-20 md:py-10 py-10 px-10 bg-black text-white items-center w-full"
        >
          <div>
            <img src={contect3} alt="" />
          </div>
          <div className="flex flex-col">
            <p>WE ARE OPEN MONDAY-FRIDAY</p>
            <p>7:00 am - 9:00 pm</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
