import React from "react";
import teammerber1 from "../img/team-1.jpg";
import teammerber2 from "../img/team-2.jpg";
import teammerber3 from "../img/team-3.jpg";
import teammerber4 from "../img/team-4.jpg";
const Team = () => {
  return (
    <div>
      {/* Heders start  */}
      <div className="text-center md:my-10 my-5">
        <div className="text-Red font-bold text-xl">DELIVERY TEAM</div>
        <div className="md:text-4xl text-2xl font-bold">
          Meet Our Delivery Team
        </div>
      </div>

      {/* Heders END  */}

      <div className="flex justify-center ">
        <div className="max-w-[1280px] flex justify-center md:flex-row flex-col flex-wrap md:my-5 my-5 gap-10">
          <div className=" md:mx-0 mx-5">
            <div className="  ">
              <div className="items-center justify-center flex flex-col gap-3 
              font-bold text-lg ">
                <img src={teammerber1} alt="" className="md:w-[250px] w-96" />
              </div>
              <div
                className="bg-Red text-white flex flex-col gap-2 items-center 
                justify-center font-bold text-lg  md:py-5 py-3 "
              >
                <div>Full Name</div>
                <div>Designation</div>
              </div>
            </div>
          </div>
          <div className=" md:mx-0 mx-5">
            <div className="  ">
              <div className="items-center justify-center flex flex-col gap-3 
              font-bold text-lg ">
                <img src={teammerber2} alt="" className="md:w-[250px] w-96" />
              </div>
              <div
                className="bg-Red text-white flex flex-col gap-2 items-center 
                justify-center font-bold text-lg  md:py-5 py-3 "
              >
                <div>Full Name</div>
                <div>Designation</div>
              </div>
            </div>
          </div>
          <div className=" md:mx-0 mx-5">
            <div className="  ">
              <div className="items-center justify-center flex flex-col gap-3 
              font-bold text-lg ">
                <img src={teammerber3} alt="" className="md:w-[250px] w-96" />
              </div>
              <div
                className="bg-Red text-white flex flex-col gap-2 items-center 
                justify-center font-bold text-lg  md:py-5 py-3 "
              >
                <div>Full Name</div>
                <div>Designation</div>
              </div>
            </div>
          </div>
          <div className=" md:mx-0 mx-5">
            <div className="  ">
              <div className="items-center justify-center flex flex-col gap-3 
              font-bold text-lg ">
                <img src={teammerber4} alt="" className="md:w-[250px] w-96" />
              </div>
              <div
                className="bg-Red text-white flex flex-col gap-2 items-center 
                justify-center font-bold text-lg  md:py-5 py-3 "
              >
                <div>Full Name</div>
                <div>Designation</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
