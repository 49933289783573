import React from 'react'

const Heders = () => {
  return (
    <div>
         {/* Header start  */}
      <div className="selection:bg-Green selection:text-white mx-auto w-full md:h-[30rem]  h-96 py-10 px-4 bg-black bg-cover md:bg-top bg-center">
        {/* Content Section */}
        <div className="flex flex-col justify-center text-center items-center md:h-[25rem] h-96 mx-auto">
          <h1 className="text-Red font-bold uppercase  py-2 md:text-4xl text-xl mb-3">
          Safe & Faster
          </h1>
          <h1 className="text-white font-bold uppercase  py-2 md:text-6xl text-xl mb-3">
          Logistics Services          </h1>
          <p className=" text-center text-white font-subheading   md:text-lg text-xs md:w-[980px] max-w-fit">
            <input type="text"
            placeholder='Traking Id'
            className='md:w-96 md:px-10 md:py-2 px-5 py-2'/>
            <buthon className='bg-Red md:px-10 md:py-3 px-5 py-2' >
            Traking And Track </buthon>
          </p>
        </div>
        {/* Content Section Ends*/}
      </div>

      {/* Header End  */}

    </div>
  )
}

export default Heders