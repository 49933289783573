import React from "react";
import why1 from '../images/why1.png'
import why2 from '../images/why2.png'
const Choose = () => {
  return (
    <div>
      <div>
        <div>
          <div className="md:py-5 py-2">
            <div className="flex flex-col items-center md:mx-2 mx-5">
              <h1 className="font-bold text-4xl  mx-2 text-blue-500">
                Why Choose Us
              </h1>

              <div></div>
              <div className="hidden">
                nternet. It uses a dictionary of over 200 Latin words, combined
                with .
              </div>
            </div>
          </div>
        </div>

        {/* content start  */}

        <div className="flex md:flex-row flex-col justify-center items-center gap-20 ">
          <div className="flex flex-col gap-5 md:w-[450px]  w-auto md:px-5 px-10">
            <div className="flex items-center">
              <div>
                <img src={why1} alt="" />
              </div>
              <div className="font-bold text-2xl">Our Vission</div>
            </div>
            <div>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters,
            </div>
            <div className="font-bold text-xl">Read More ----</div>
          </div>
          <div className="flex flex-col gap-5 md:w-[450px]  w-auto md:px-5 px-10">
            <div className="flex items-center ">
              <div>
                <img src={why2} alt="" />
              </div>
              <div className="font-bold text-2xl">Estimate Shipping</div>
            </div>
            <div>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters,
            </div>
            <div className="font-bold text-xl">Read More ----</div>
          </div>
          
       
        </div>
        {/* content End  */}
      </div>
    </div>
  );
};

export default Choose;
